<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketVerdictAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <div>
              <p class="h4 mb-1">
                <img
                  src="@/assets/images/icons/icon_judgment.svg"
                  height="28"
                  style="padding-right: 8px;"
                />
                อนุญาโตตุลาการ
              </p>
              <b-row>
                <b-col
                  sm="12"
                  md="4">
                  <DatePicker
                    v-model="initialVerdict.arbitrationCourtJudgmentDate"
                    :rules="{ required: false }"
                    name="วันพิพากษา"
                    label="วันพิพากษา" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <SelectField
                    v-model="initialVerdict.arbitrationCourtCaseOutcome"
                    :rules="{ required: false }"
                    name="ผลคดี"
                    form-label="ผลคดี"
                    placeholder="ผลคดี"
                    label-option="name"
                    return-value="id"
                    :options="outcome"
                  />
                </b-col>
                <b-col
                  sm="12"
                  md="4"></b-col>
                <b-col
                  sm="12"
                  md="12">
                  <TextAreaInput
                    v-model="initialVerdict.arbitrationCourtJudgmentSummary"
                    :name="`คําพิพากษา(โดยย่อ)`"
                    :label="`คําพิพากษา(โดยย่อ)`" />
                </b-col>
              </b-row>
            </div>
            <div class="pt-1">
              <p class="h4 mb-1">
                <img
                  src="@/assets/images/icons/icon_judgment.svg"
                  height="28"
                  style="padding-right: 8px;"
                />
                ศาลชั้นต้น
              </p>
              <b-row>
                <b-col
                  sm="12"
                  md="4">
                  <DatePicker
                    v-model="initialVerdict.civilCourtJudgmentDate"
                    :rules="{ required: false }"
                    name="วันพิพากษา"
                    label="วันพิพากษา" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <SelectField
                    v-model="initialVerdict.civilCourtCaseOutcome"
                    :rules="{ required: false }"
                    name="ผลคดี"
                    form-label="ผลคดี"
                    placeholder="ผลคดี"
                    label-option="name"
                    return-value="id"
                    :options="outcome"
                  />
                </b-col>
                <b-col
                  sm="12"
                  md="4"></b-col>
                <b-col
                  sm="12"
                  md="12">
                  <TextAreaInput
                    v-model="initialVerdict.civilCourtJudgmentSummary"
                    :name="`คําพิพากษา(โดยย่อ)`"
                    :label="`คําพิพากษา(โดยย่อ)`" />
                </b-col>
              </b-row>
            </div>
            <div class="pt-1">
              <p class="h4 mb-1">
                <img
                  src="@/assets/images/icons/icon_judgment.svg"
                  height="28"
                  style="padding-right: 8px;"
                />
                ศาลอุทธรณ์
              </p>
              <b-row>
                <b-col
                  sm="12"
                  md="4">
                  <DatePicker
                    v-model="initialVerdict.appealCourtJudgmentDate"
                    :rules="{ required: false }"
                    name="วันพิพากษา"
                    label="วันพิพากษา" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <SelectField
                    v-model="initialVerdict.appealCourtCaseOutcome"
                    :rules="{ required: false }"
                    name="ผลคดี"
                    form-label="ผลคดี"
                    placeholder="ผลคดี"
                    label-option="name"
                    return-value="id"
                    :options="outcome"
                  />
                </b-col>
                <b-col
                  sm="12"
                  md="4"></b-col>
                <b-col
                  sm="12"
                  md="12">
                  <TextAreaInput
                    v-model="initialVerdict.appealCourtJudgmentSummary"
                    :name="`คําพิพากษา(โดยย่อ)`"
                    :label="`คําพิพากษา(โดยย่อ)`" />
                </b-col>
              </b-row>
            </div>
            <div class="pt-1">
              <p class="h4 mb-1">
                <img
                  src="@/assets/images/icons/icon_judgment.svg"
                  height="28"
                  style="padding-right: 8px;"
                />
                ศาลฎีกา
              </p>
              <b-row>
                <b-col
                  sm="12"
                  md="4">
                  <DatePicker
                    v-model="initialVerdict.supremeCourtJudgmentDate"
                    :rules="{ required: false }"
                    name="วันพิพากษา"
                    label="วันพิพากษา" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <SelectField
                    v-model="initialVerdict.supremeCourtCaseOutcome"
                    :rules="{ required: false }"
                    name="ผลคดี"
                    form-label="ผลคดี"
                    placeholder="ผลคดี"
                    label-option="name"
                    return-value="id"
                    :options="outcome"
                  />
                </b-col>
                <b-col
                  sm="12"
                  md="4"></b-col>
                <b-col
                  sm="12"
                  md="12">
                  <TextAreaInput
                    v-model="initialVerdict.supremeCourtJudgmentSummary"
                    :name="`คําพิพากษา(โดยย่อ)`"
                    :label="`คําพิพากษา(โดยย่อ)`" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <DatePicker
                    v-model="initialVerdict.supremeCourtDateOfCaseClosing"
                    :rules="{ required: false }"
                    name="วันที่สิ้นสุดคดี"
                    label="วันที่สิ้นสุดคดี" />
                </b-col>
                <b-col
                  sm="12"
                  md="4">
                  <DatePicker
                    v-model="initialVerdict.supremeCourtDateOfCompletionOfTheCase"
                    :rules="{ required: false }"
                    name="วันที่เสร็จเก็บคดี"
                    label="วันที่เสร็จเก็บคดี" />
                </b-col>
              </b-row>
            </div>
            <div class="mt-5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addVerdict()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import DatePicker from '@/components/Form/DatePicker.vue'
import SelectField from '@/components/Form/SelectField.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import VerdictProvider from '@/resources/VerdictProvider'

const VerdictService = new VerdictProvider()

export default {
  components: {
    DatePicker,
    SelectField,
    TextAreaInput
  },
  props: {
    initialVerdict: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      outcome: [
        { id: 'test1', name: 'test1' },
        { id: 'test2', name: 'test2' }
      ]
    }
  },
  computed: {
    verdict: {
      get () {
        return this.initialVerdict
      },
      set () {
        this.$emit('update:verdict-value')
      }
    }
  },
  methods: {
    resetForm () {
      this.$refs.TicketVerdictAddForm.reset()
    },
    async addVerdict () {
      try {
        const payload = { ...this.verdict, caseId: this.ticketId }
        await VerdictService.create(payload)
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Judgment Decision has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text--bold {
  font-weight: bold;
}
</style>
