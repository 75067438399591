<template>
  <div>
    <b-card>
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive>
        <template #cell(createdAt)="data">
          <div v-if="data && data.item">
            {{ data.item.createdAt | formatDateEn }}
          </div>
        </template>
      </b-table>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)">
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketMovementCaseAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="movementCase.record"
                  :name="`บันบึกความเคลื่อนไหว`"
                  :label="`บันบึกความเคลื่อนไหว`" />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="movementCase.note"
                  :name="`หมายเหตุ`"
                  :label="`หมายเหตุ`" />
              </b-col>
            </b-row>
            <div class="d-block d-sm-flex align-items-center mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                class=""
                @click="addMovementCase()">
                <div class="d-flex align-items-center">
                  <img
                    src="@/assets/images/icons/gala_add.svg" />
                  <span class="pl-1">เพิ่มนัด</span>
                </div>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import MovementCaseProvider from '@/resources/MovementCaseProvider'

const MovementCaseService = new MovementCaseProvider()

export default {
  components: {
    TextAreaInput
  },
  props: {
    initialMovementCase: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      fields: [
        { key: 'record', label: 'บันทึกความเคลือนไหว' },
        { key: 'note', label: 'หมายเหตุ' },
        { key: 'caseId', label: 'ผู้ใช้งาน' },
        { key: 'createdAt', label: 'วันที่บันทึก' }
      ],
      items: [],
      filter: {
        search: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      }
    }
  },
  computed: {
    movementCase: {
      get () {
        return this.initialMovementCase
      },
      set () {
        this.$emit('update:movement-case-value')
      }
    }
  },
  async created () {
    try {
      // await this.getMovementCaseByTicketId()
      setInterval(async () => {
        await this.getMovementCaseByTicketId()
      }, 5000)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    resetForm () {
      this.$refs.TicketMovementCaseAddForm.reset()
    },
    // async getMovementCase () {
    //   const data = await MovementCaseService.paginate(this.pagination.page, this.pagination.limit, this.filter)
    //   this.setPagination(data.page, data.totalDocs)
    //   this.items = [...data.data]
    // },
    async getMovementCaseByTicketId () {
      try {
        const data = await MovementCaseService.findByTicketId(this.ticketId)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (error) {
        console.log('error', error)
      }
    },
    async addMovementCase () {
      try {
        const payload = { ...this.movementCase, caseId: this.ticketId }
        await MovementCaseService.create(payload)
        this.resetForm()
        this.getMovementCaseByTicketId()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Movement Case has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getMovementCaseByTicketId()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
