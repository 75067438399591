import HttpRequest from './HttpRequest'

class MovementCaseProvider extends HttpRequest {
  findAll () {
    return this.get('/movement-of-the-case')
  }

  findOne (id) {
    return this.get(`/movement-of-the-case/${id}`)
  }

  findByTicketId (id) {
    return this.get(`/movement-of-the-case/all/case-id/${id}`)
  }

  create (payload) {
    return this.post('/movement-of-the-case', payload)
  }

  update (id, payload) {
    return this.patch(`/movement-of-the-case/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/movement-of-the-case/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/movement-of-the-case?page=${page}&limit=${limit}`, query)
  }
}

export default MovementCaseProvider
