import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CustomerProvider extends HttpRequest {
  findAll () {
    return this.get('/customer-profile')
  }

  findOne (id) {
    return this.get(`/customer-profile/${id}`)
  }

  findByCreated (id) {
    return this.get(`/customer-profile/${id}/customer`)
  }

  findById (id) {
    return this.get(`/customer-profile/${id}`)
  }

  findByAni (ani) {
    return this.get(`/customer-profile/${ani}/ani`)
  }

  create (payload) {
    return this.post('/customer-profile', payload)
  }

  createCustomerAndContact (payload) {
    return this.post('/customer-profile/customer-contact', payload)
  }

  update (id, payload) {
    return this.patch(`/customer-profile/${id}`, payload)
  }

  delById (id) {
    return this.delete(`/customer-profile/${id}`)
  }

  editById (id) {
    return this.patch(`/customer-profile/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/customer-profile?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default CustomerProvider
