<template>
  <b-modal
    id="sms-modal"
    v-model="modalShow"
    v-bind="$attrs"
    size="xl"
    modal-class="modal-normal"
    centered
    ok-title="Send"
    v-on="{...$listeners}">
    <template #modal-header>
      <div
        class="mx-auto pt-1">
        <h2 class="font-weight-light">
          Send SMS
        </h2>
      </div>
    </template>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <div class="mb-2">
          <TextFieldInput
            v-model="phoneNumber"
            label="เบอร์โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            @keyup.enter.native="fetchFilter()" />
        </div>
      </b-col>
      <b-col
        cols="12"
        md="6"
      ></b-col>
      <b-col
        cols="12"
        md="6">
        <SelectField
          v-model="template"
          name="Template"
          form-label="Template"
          placeholder="Template"
          label-option="name"
          return-value="id"
          :options="templateOptions" />
      </b-col>
      <b-col
        cols="12"
        md="6">
      </b-col>
      <b-col
        cols="12"
        md="12">
        <TextAreaInput
          v-model="message"
          name="ข้อความ"
          label="ข้อความ" />
      </b-col>
    </b-row>
    <template #modal-footer="{ hide }">
      <div>
        <b-button
          class="mr-1"
          variant="success">
          Send
        </b-button>
        <b-button
          variant="outline-danger"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    TextAreaInput
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    initSubject: {
      type: String,
      default: ''
    },
    initMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      phoneNumber: '',
      template: [],
      templateOptions: [
        { id: 1, name: 'template 1' },
        { id: 2, name: 'template 2' }
      ],
      message: ''
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    fetchFilter () {
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: flex-start;
}

div ::v-deep .modal-header {
  background-color: white;
}
</style>
