<template>
  <div v-if="isFullLoaded">
    <ConfirmModal2
      ref="confirmModal"
      variant="danger"
      :title="confirmModal.title"
      :description="confirmModal.description"
    />
    <ConfirmModal
      v-model="isConfirmModalActive"
      :items="addItem"
      title="Confirm to Add Case"
      @confirm="confirmAdd()" />
    <ReOpenConfirmModal
      ref="reOpenConfirmModal"
      :remark.sync="remark"
      @update-remark="remark = $event"
    />
    <ReferModal
      ref="referModal"
      :ticket-code-refer.sync="ticketCodeRefer"
      @update-ticket-code-refer="ticketCodeRefer = $event"
    />
    <StatusModal
      v-model="isSubmitModalActive"
      status-title="Confirm to Add Case"
      status-color="#28C76F"
      status-icon="CheckCircleIcon"
      @discard="onDiscard()" />
    <!-- <KnowledgeModal
      ref="knowledgeModal"
      v-model="isKnowledgeModal"
      :knowledge-items="knowledgeItems"
      :pagination="knowledgePagination"
      :search.sync="searchKnowledge"
      @fetch-search="fetchSearchKnowledge()"
      @selectd-row="selectdKnowledge($event)"
      @show-current-page="showCurrentPageKnowledge($event)"
    /> -->
    <!-- <SendToEmailModal
      v-if="isSendEmailModal"
      v-model="isSendEmailModal"
      :init-subject.sync="subjectModifier"
      :init-message.sync="messageModifier"
      @click="sendEmail($event)"
      @send-email="sendEmail($event)"
    /> -->
    <SendEmailModel
      v-if="isSendEmailModal"
      v-model="isSendEmailModal" />
    <SendToSmsModal
      v-if="isSendSmsModal"
      v-model="isSendSmsModal" />
    <SaveAndClosedModal
      v-if="isSaveAndClosedModal"
      v-model="isSaveAndClosedModal" />
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketAddForm">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <p class="h4 mb-1">
              <b-avatar
                class="grad--primary"
                icon="person-fill"
                size="2rem"></b-avatar>
              ข้อมูลผู้ติดต่อ
            </p>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.customerName"
                  :rules="{ required: true }"
                  name="ชื่อลูกค้า/บริษัท"
                  placeholder="ชื่อผู้ติดต่อ"
                  label="ชื่อลูกค้า/บริษัท"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactName"
                  :rules="{ required: false }"
                  name="ชื่อผู้ติดต่อ"
                  label="ชื่อผู้ติดต่อ"
                  placeholder="ชื่อผู้ติดต่อ"
                  trim />
              </b-col>
              <!-- <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.customerLevel"
                  :rules="{ required: false }"
                  name="Customer Level"
                  label="Customer Level"
                  placeholder="Customer Level"
                  disabled
                  trim />
              </b-col> -->
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.contactRelationId"
                  :rules="{ required: false }"
                  name="ความสัมพันธ์ผู้ติดต่อ"
                  form-label="ความสัมพันธ์ผู้ติดต่อ"
                  placeholder="ความสัมพันธ์ผู้ติดต่อ"
                  label-option="name"
                  return-value="id"
                  :options="allRef.contactRelation"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.policyNumber"
                  :rules="{ required: false }"
                  name="เลขที่กรมธรรม์"
                  label="เลขที่กรมธรรม์"
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.groupPolicyNumber"
                  :rules="{ required: false }"
                  name="เลขที่กรมธรรม์กลุ่ม"
                  label="เลขที่กรมธรรม์กลุ่ม"
                  placeholder="เลขที่กรมธรรม์กลุ่ม"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactNumberPhone"
                  :rules="{ required: false }"
                  name="เบอร์โทรศัพท์"
                  label="เบอร์โทรศัพท์"
                  placeholder="เบอร์โทรศัพท์"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.contactChannelId"
                  :rules="{ required: false }"
                  name="ช่องทางการติดต่อ"
                  form-label="ช่องทางการติดต่อ"
                  placeholder="ช่องทางการติดต่อ"
                  label-option="name"
                  return-value="id"
                  :options="allRef.contactChannel"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.contactTypeId"
                  :rules="{ required: false }"
                  name="ประเภทผู้ติดต่อ"
                  form-label="ประเภทผู้ติดต่อ"
                  placeholder="ประเภทผู้ติดต่อ"
                  label-option="name"
                  return-value="id"
                  :options="allRef.contactType"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.email"
                  :rules="{ required: false }"
                  name="อีเมล"
                  label="อีเมล"
                  placeholder="อีเมล"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.oicListId"
                  :rules="{ required: false }"
                  name="คปภ. พื้นที่"
                  form-label="คปภ. พื้นที่"
                  placeholder="คปภ. พื้นที่"
                  label-option="oicName"
                  return-value="id"
                  :options="allRef.oicList"
                />
              </b-col>
            </b-row>
            <p class="h4 mb-1 mt-2">
              <b-avatar
                class="grad--primary"
                icon="file-earmark-text"
                size="2rem"></b-avatar>
              รายละเอียดการแจ้งเรื่อง
            </p>
            <b-row>
              <!-- <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.code"
                  :rules="{ required: true }"
                  name="Case ID"
                  :label="`Case ID ${ticket.ticketCodeRefer ? `(Refer: ${ticket.ticketCodeRefer})` : ''}` "
                  trim />
              </b-col> -->
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.createdAt | formatDateTimeEn"
                  :rules="{ required: false }"
                  name="วันที่แจ้งเรื่อง"
                  label="วันที่แจ้งเรื่อง"
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.caseStatus | formatcaseStatus"
                  :rules="{ required: false }"
                  name="Case Status"
                  label="Case Status"
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.statusSla"
                  :rules="{ required: false }"
                  name="สถานะ SLA"
                  label="สถานะ SLA"
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.caseType"
                  :disabled="!lvlOptions.lvlOneOptions || lvlOptions.lvlOneOptions.length <= 0"
                  name="Case Type"
                  form-label="Case Type"
                  placeholder="Case Type"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: isRequiredCategory }"
                  :options="lvlOptions.lvlOneOptions"
                  @input="selectedItem($event, 1)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.caseSubject"
                  :disabled="!lvlOptions.lvlTwoOptions || lvlOptions.lvlTwoOptions.length <= 0"
                  name="Case Subject"
                  form-label="Case Subject"
                  placeholder="Case Subject"
                  :options="lvlOptions.lvlTwoOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 2)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.subSubject"
                  :disabled="!lvlOptions.lvlThreeOptions || lvlOptions.lvlThreeOptions.length <= 0"
                  name="Sub Subject"
                  form-label="Sub Subject"
                  placeholder="Sub Subject"
                  :options="lvlOptions.lvlThreeOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 3)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.subSubject2"
                  :disabled="!lvlOptions.lvlFourOptions || lvlOptions.lvlFourOptions.length <= 0"
                  name="Sub Subject2"
                  form-label="Sub Subject2"
                  placeholder="Sub Subject2"
                  :options="lvlOptions.lvlFourOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 4)"
                />
              </b-col>
              <b-col
                sm="12"
                md="8"></b-col>
              <!-- <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.subSubject3"
                  :disabled="!lvlOptions.lvlFiveOptions || lvlOptions.lvlFiveOptions.length <= 0"
                  name="Sub Subject3"
                  form-label="Sub Subject3"
                  placeholder="Sub Subject3"
                  :options="lvlOptions.lvlFiveOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 5)"
                />
              </b-col> -->
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.title"
                  :rules="{ required: false }"
                  name="หัวเรื่อง / Title"
                  label="หัวเรื่อง / Title"
                  placeholder="หัวเรื่อง / Title"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.specialCaseId"
                  :rules="{ required: false }"
                  name="Special Case"
                  form-label="Special Case"
                  placeholder="Special Case"
                  label-option="name"
                  return-value="id"
                  :options="allRef.specialCase"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <DatePicker
                  v-model="ticket.dueDate"
                  :rules="{ required: true }"
                  name="Due Date"
                  label="Due Date" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.levelOfProblem"
                  :rules="{ required: false }"
                  name="Level of Problem"
                  form-label="Level of Problem"
                  placeholder="Level of Problem"
                  label-option="name"
                  return-value="id"
                  :options="allRef.levelOfProblem"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.severity"
                  :rules="{ required: false }"
                  name="Severity"
                  form-label="Severity"
                  placeholder="Severity"
                  label-option="name"
                  return-value="id"
                  :options="allRef.severity"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.solutionTypeId"
                  :rules="{ required: false }"
                  name="วีธีการแก้ปัญหา"
                  form-label="วีธีการแก้ปัญหา"
                  placeholder="วีธีการแก้ปัญหา"
                  label-option="name"
                  return-value="id"
                  :options="allRef.solutionType"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.controlNumber"
                  :rules="{ required: false }"
                  name="เลขที่คุม"
                  label="เลขที่คุม"
                  placeholder="เลขที่คุม"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.controlCode"
                  :rules="{ required: false }"
                  name="รหัสควบคุม (คปภ.)"
                  label="รหัสควบคุม (คปภ.)"
                  placeholder="รหัสควบคุม (คปภ.)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.accused"
                  :rules="{ required: false }"
                  name="ผู้ถูกกล่าวอ้าง (ตท.)"
                  label="ผู้ถูกกล่าวอ้าง (ตท.)"
                  placeholder="ผู้ถูกกล่าวอ้าง (ตท.)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Problem Detail"
                >
                  <wysiwyg v-model="ticket.problemDetail" />
                  <!-- <quill-editor
                    v-model="ticket.ticketDetail"
                    :rules="{ required: false }"
                    name="Incident Detail"
                    label="Incident Detail"
                    placeholder="Input Incident Detail"
                    trim /> -->
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Solution Detail"
                >
                  <wysiwyg v-model="ticket.solutionDetail" />
                  <!-- <quill-editor
                    v-model="ticket.ticketDetail"
                    :rules="{ required: false }"
                    name="Incident Detail"
                    label="Incident Detail"
                    placeholder="Input Incident Detail"
                    trim /> -->
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="ticket.noteRemark"
                  :rules="{ required: false }"
                  name="Note/Remark"
                  label="Note/Remark" />
              </b-col>
            </b-row>
            <p>Voice File Link</p>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <b-form-input
                  v-model="ticket.voiceFileLink"
                  placeholder="Voice File Link"
                  class="mr-1">
                </b-form-input>
              </b-col>
              <b-col
                sm="12"
                md="8">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  class="">
                  <div class="d-flex align-items-center">
                    <img
                      src="@/assets/images/icons/majesticons_file-plus-line.svg" />
                    <span class="pl-1">Attach File</span>
                  </div>
                </b-button>
              </b-col>
            </b-row>
            <!-- <div class="d-flex align-items-center">
              <b-form-input
                v-model="ticket.voiceFileLink"
                placeholder="Voice File Link"
                class="w-25 mr-1">
              </b-form-input>
            </div> -->

            <div class="d-flex mt-2 justify-content-between mb-2">
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2 btn-submit"
                  type="submit"
                >
                  Save
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  style="height: 40px;"
                  variant="outline-success"
                  class="mr-2 btn-submit"
                  @click="showSaveAndClosed()"
                >
                  <span class="align-middle">Save and Closed</span>
                </b-button>
              </div>
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-secondary"
                  class="">
                  <div class="d-flex align-items-center">
                    <img
                      src="@/assets/images/icons/mdi_page-next-outline.svg" />
                    <span class="pl-1">Assign Case</span>
                  </div>
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(94,88,115)'"
                  style="background: #5E5873 !important"
                  class="mr-2 btn-submit"
                >
                  Assign Case
                </b-button> -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-purple"
                  class="mx-2">
                  <div class="d-flex align-items-center">
                    <img
                      src="@/assets/images/icons/system-uicons_duplicate.svg" />
                    <span class="pl-1">Duplicate</span>
                  </div>
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2 btn-submit"
                >
                  Duplicate
                </b-button> -->
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="danger"
                  type="button"
                  class="mr-2 btn-submit">
                  <b-icon icon="trash"></b-icon>
                </b-button> -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-danger"
                  class="">
                  <div class="d-flex align-items-center">
                    <img
                      src="@/assets/images/icons/solar_trash-bin-minimalistic-linear.svg" />
                    <span class="pl-1">Delete</span>
                  </div>
                </b-button>
              </div>
            </div>
            <div class="d-flex mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-secondary"
                class="mr-2"
                @click="showSendEmailModal()">
                <div class="d-flex align-items-center">
                  <img
                    src="@/assets/images/icons/icon_send-email.svg" />
                  <span class="pl-1">Send Email</span>
                </div>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-secondary"
                class=""
                @click="showSendSmsModal()">
                <div class="d-flex align-items-center">
                  <img
                    src="@/assets/images/icons/material-symbols_sms-outline.svg" />
                  <span class="pl-1">Send SMS</span>
                </div>
              </b-button>
            </div>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.remark"
                  :rules="{ required: false }"
                  name="Re Open Remark"
                  label="Re Open Remark"
                  placeholder="Re Open Remark"
                  trim />
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="outline-danger"
              class="">
              <div class="d-flex align-items-center">
                <img
                  src="@/assets/images/icons/uil_redo.svg" />
                <span class="pl-1">Re Open</span>
              </div>
            </b-button>

            <!-- <p class="h4 mb-0 mt-3">
              <b-icon icon="file-earmark-text"></b-icon>
              ไม่เกี่ยวข้อง (แบบเก่า)
            </p>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <AutoCompleteField
                  v-model="userName"
                  name="Progress By"

                  label="Progress By"
                  placeholder="Search User By Name"
                  item-value="fullName"
                  :filter-items="filterUser"
                  :items="userItems"
                  @keydown="searchUserByName()"
                  @select="selectUser($event)"
                >
                  <template #item-text="{ item }">
                    {{ item.fullName }}
                  </template>
                </AutoCompleteField>
              </b-col>
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="ticket.subject"
                  :rules="{ required: false }"
                  name="Subject"
                  label="Subject"
                  placeholder="Input Subject"
                  trim />
              </b-col>
            </b-row> -->
            <!-- <b-button
              v-ripple.400="'rgba(94,88,115)'"
              style="background: #5E5873 !important"
              class="mt-3"
              type="button"
              @click="openFile()"
            >
              Attach Files
            </b-button>
            <input
              ref="addFiles"
              class="d-none"
              type="file"
              multiple
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*"
              @change="onChangeFile($event)"
            > -->
            <!-- <b-col
              class="my-2"
              cols="12">
              <ul>
                <li
                  v-for="(item, index) in files"
                  :key="index"
                  class="my-2"
                >
                  <span class="relative">
                    <label class="remove-file">
                      <span
                        class="pointer"
                        @click="removeFile(index)">
                        X
                      </span>
                    </label>
                    <a
                      :href="item.url"
                      target="_blank">{{ item.name }}</a>
                  </span>
                </li>
              </ul>
            </b-col> -->
            <!-- <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mt-5 mr-2 btn-submit"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-5 mr-2 btn-submit"
                @click="saveAndClosed()"
              >
                Save and closed
              </b-button>
              <b-button
                v-if="!includeStatus(ticket.caseStatus, 'closed', 'void')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="referTicket()">
                Refer
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="warning"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="showSendEmailModal()"
              >
                Send to email
              </b-button>
              <b-button
                v-if="!includeStatus(ticket.caseStatus, 'closed', 'void')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="voidTicket()">
                Void
              </b-button>
              <b-button
                v-if="includeStatus(ticket.caseStatus, 'closed')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="duplicateTicket()">
                Duplicate
              </b-button>
              <b-button
                v-if="includeStatus(ticket.caseStatus, 'closed')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="success"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="reOpenTicket()">
                Re Open
              </b-button>
            </div> -->
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import ConfirmModal2 from '@/components/ConfirmModal.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
// import AutoCompleteField from '@/components/Form/AutoCompleteField.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import ConfirmModal from '@/components/Modal/ConfirmModal.vue'
import StatusModal from '@/components/Modal/StatusModal.vue'
import DatePicker from '@/components/Form/DatePicker.vue'
// import TipTab from '@/components/TipTab.vue'
// import KnowledgeModal from '@/components/Modal/KnowledgeModal.vue'
// import SendToEmailModal from '@/components/Modal/SendToEmailModal.vue'
import KnowledgeProvider from '@/resources/KnowledgeBaseProvider'
import UserProvider from '@/resources/UserProvider'
import TicketProvider from '@/resources/TicketProvider'
import MicrosoftProvider from '@/resources/MicrosoftProvider'
import SendEmailModel from '@/components/Modal/SendEmailModel.vue'
import SendToSmsModal from '@/components/Modal/SendToSmsModal.vue'
import SaveAndClosedModal from '@/components/Modal/SaveAndClosedModal.vue'
import ReOpenConfirmModal from './ReOpenConfirmModal.vue'
import ReferModal from './ReferModal.vue'

const KnowledgeService = new KnowledgeProvider()
const TicketService = new TicketProvider()
const UserService = new UserProvider()
const MicrosoftService = new MicrosoftProvider()

export default {
  components: {
    ReOpenConfirmModal,
    TextFieldInput,
    // AutoCompleteField,
    SelectField,
    TextAreaInput,
    ConfirmModal,
    StatusModal,
    // KnowledgeModal,
    // SendToEmailModal,
    ConfirmModal2,
    ReferModal,
    DatePicker,
    SendEmailModel,
    SendToSmsModal,
    SaveAndClosedModal
    // TipTab
  },
  props: {
    allRef: {
      type: Object,
      default: () => {}
    },
    initialTicket: {
      type: Object,
      default: () => ({})
    },
    initialResolveDate: {
      type: String,
      default: ''
    },
    initialDueDate: {
      type: String,
      default: ''
    },
    tabIndex: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      remark: '',
      ticketCodeRefer: null,
      isFullLoaded: false,
      searchKnowledge: '',
      isConfirmModalActive: false,
      isSubmitModalActive: false,
      isKnowledgeModal: false,
      isSendEmailModal: false,
      isSendSmsModal: false,
      isSaveAndClosedModal: false,
      isRequiredCategory: false,
      userItems: [],
      userName: '',
      priorityOption: [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' }
      ],
      isRemark1: true,
      isRemark2: false,
      isRemark3: false,
      addItem: {},
      lvlOptions: {
        lvlOneOptions: [],
        lvlTwoOptions: [],
        lvlThreeOptions: [],
        lvlFourOptions: [],
        lvlFiveOptions: [],
        lvlSixOptions: []
      },
      currentParentId: null,
      knowledgeItems: [],
      files: [],
      knowledgePagination: {
        page: 1,
        totalDocs: 1,
        limit: 10
      },
      confirmModal: {
        title: 'Are you sure ?',
        description: 'You want to Void ?'
      }
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    }),
    categories: {
      get () {
        return this.allRef.categories
      }
    },
    ticket: {
      get () {
        return this.initialTicket
      },

      set () {
        this.$emit('update:ticket-value')
      }
    },
    resolveDate: {
      get () {
        return this.initialResolveDate
      },

      set () {
        this.$emit('update:resolve-date-value')
      }
    },
    dueDate: {
      get () {
        return this.initialDueDate
      },

      set () {
        this.$emit('update:due-date-value')
      }
    },
    subjectModifier: {
      get () {
        return `[${this.ticket.code}] ${this?.ticket?.subject || ''}`
      },

      set (val) {
        this.$emit('update:subject', val)
      }
    },
    messageModifier () {
      return `
        ${this.ticket.ticketDetail ? `<p><strong>Incident Detail</strong></p> ${this.ticket.ticketDetail}` : ''}
      `
    }
  },
  async created () {
    try {
      this.$store.dispatch('activity/increaseLoading')
      this.ticketCodeRefer = this.ticket.ticketCodeRefer
      await this.getAllKnowledgeByCategory()
      this.setOptionLvlOne(this.categories)
      this.userName = `${this.profile.firstName} ${this.profile.lastName}`
    } finally {
      this.ticketCodeRefer = this.ticket.ticketCodeRefer
      this.$store.dispatch('activity/decreaseLoading')
      this.isFullLoaded = true
    }
  },
  methods: {
    ...mapActions({
      deleteTicket: 'ticket/DELETE_TICKET'
      // overwriteTicket: 'ticket/OVERWRITE_TICKET'
    }),
    /**
     *
     * @param {Object} payload
     * @param {string} payload.subject
     * @param {string} payload.message
     * @param {string} payload.to
     * @param {string} payload.cc
     */
    async sendEmail (payload) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        await MicrosoftService.sendMail({
          subject: payload?.subject,
          html: payload?.message,
          to: payload?.to,
          cc: payload?.cc
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: 'Email Send Successfully'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'Email Send Failed'
          }
        })
      } finally {
        this.isFullLoaded = false
        this.isSendEmailModal = false
        this.$nextTick(() => {
          this.isFullLoaded = true
        })
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getTicketById () {
      try {
        await TicketService.findOne()
      } catch (error) {
        console.error(error)
      }
    },
    async getAllKnowledgeByCategory () {
      try {
        const data = await KnowledgeService.paginate(this.knowledgePagination.page, this.knowledgePagination.limit, {
          searchCodeAndSubject: this.searchKnowledge
        })
        this.setPaginationKnowledge(data.page, data.totalDocs)
        this.knowledgeItems = data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getKnowledgeByCategory () {
      try {
        const categoryId = this.getLastItem()
        const { data } = await KnowledgeService.findAllByCategory(categoryId)
        if (data && Array.isArray(data) && data.length > 0) {
          const knowledge = data[0]
          this.ticket.ticketDetail = knowledge.problemDetail
          this.ticket.ticketResolve = knowledge.solutionDetail
        } else {
          this.setInitialKnowledge()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async searchUserByName () {
      try {
        setTimeout(async () => {
          const { data } = await UserService.paginate(1, 10, { searchFullName: this.userName })
          const result = []
          data.forEach((item) => {
            result.push({ ...item, fullName: `${item.firstName} ${item.lastName}` })
          })
          this.userItems = result
        }, 100)
      } catch (error) {
        console.error(error)
      }
    },
    async voidTicket () {
      try {
        const confirm = await this.$refs.confirmModal.show({ description: 'You want to void ticket ?' })
        if (confirm) {
          await TicketService.voidTicket(this.ticket.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Void Successfully',
              icon: 'EditIcon',
              variant: 'success',
              text: '👋 Ticket has been void'
            }
          })
          this.deleteTicket(this.tabIndex)
          this.$router.push('/case')
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    },
    async duplicateTicket () {
      try {
        const payload = {
          ...this.ticket
        }
        payload.caseStatus = 'open'
        // delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.resolveDate
        delete payload.dueDate
        delete payload.closedDate
        const { data } = await TicketService.duplicateTicket(payload)
        this.ticket = data
        this.$router.replace(`/case/detail/${data.id}`)
        this.$router.go(0)
      } catch (error) {
        console.error(error)
      }
    },
    async reOpenTicket () {
      try {
        const confirm = await this.$refs.reOpenConfirmModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (confirm) {
          const payload = {
            remark: this.remark
          }
          await TicketService.reOpenTicket(this.ticketId, payload)
          this.$router.go()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async referTicket () {
      try {
        const confirm = await this.$refs.referModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (confirm) {
          const { data } = await TicketService.findByCode(this.ticketCodeRefer)
          if (!data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Can not find Ticket ${this.ticketCodeRefer}`,
                icon: 'EditIcon',
                variant: 'danger',
                text: '👋 Refer Ticket has been failed'
              }
            })
            this.ticketCodeRefer = this.ticket.ticketCodeRefer
            return true
          }
          this.ticket.ticketCodeRefer = this.ticketCodeRefer
        }
      } catch (error) {
        console.error(error)
      }
      return true
    },
    saveAndClosed () {
      const payload = {
        ...this.ticket,
        files: this.files
      }
      this.$emit('save-and-closed', payload)
    },
    includeStatus (status, ...target) {
      return target.includes(status)
    },
    selectUser (val) {
      const payload = {
        id: val.id,
        username: val.username,
        firstName: val.firstName,
        lastName: val.lastName
      }
      this.$emit('selectd-user', payload)
    },
    filterUser (items, value) {
      const upperValue = value.toUpperCase()
      return items.filter((str) => (str && str.fullName ? str.fullName.toUpperCase().indexOf(upperValue) >= 0 : ''))
    },
    showSendEmailModal () {
      this.isSendEmailModal = !this.isSendEmailModal
    },
    showSendSmsModal () {
      this.isSendSmsModal = !this.isSendSmsModal
    },
    showSaveAndClosed () {
      this.isSaveAndClosedModal = !this.isSaveAndClosedModal
    },
    selectdKnowledge (val) {
      this.ticket.ticketDetail = val.problemDetail
      this.ticket.ticketResolve = val.solutionDetail
      this.isKnowledgeModal = false
    },
    onSubmit () {
      const payload = {
        ...this.ticket,
        files: this.files

      }
      this.$emit('add-ticket', payload)
    },
    onDiscard () {
      this.resetForm()
      this.$router.push('/case')
    },
    resetForm () {
      this.$refs.TicketAddForm.reset()
    },
    openFile () {
      this.$refs.addFiles.click()
    },
    onChangeFile (event) {
      const { files } = event.target
      for (let i = 0; i < files.length; i++) {
        this.files.push({
          file: files[i],
          name: files[i].name,
          url: URL.createObjectURL(files[i])
        })
      }
    },
    removeFile (index) {
      this.files.splice(index, 1)
    },
    openAddModal (val) {
      this.addItem = {
        'Ticket Type :': val.incidentType.label,
        'Customer Name :': val.customerName,
        'Contact Name :': val.contactName,
        'Ticket No. :': val.incidentNo
      }
      this.isConfirmModalActive = true
    },
    confirmAdd () {
      this.isSubmitModalActive = true
    },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlOptions.lvlTwoOptions = filter
        this.lvlOptions.lvlThreeOptions = []
        this.lvlOptions.lvlFourOptions = []
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv2 = null
        this.ticket.categoryLv3 = null
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 2:
        this.lvlOptions.lvlThreeOptions = filter
        this.lvlOptions.lvlFourOptions = []
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv3 = null
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 3:
        this.lvlOptions.lvlFourOptions = filter
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 4:
        this.lvlOptions.lvlFiveOptions = filter
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 5:
        this.lvlOptions.lvlSixOptions = filter
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv6 = null
        break
      default:
        break
      }
      const result = this.getLastItem()
      if (this.currentParentId !== result) {
        this.currentParentId = result
        this.$emit('selected-category', result)
      }
      this.getKnowledgeByCategory()
    },
    setOptionLvlOne (val) {
      if (val) {
        this.lvlOptions.lvlOneOptions = val.filter((item) => item.level === 1)
      }
    },
    findCategory (parentId) {
      return this.allRef.categories.filter((item) => item.parentId === parentId)
    },
    getLastItem () {
      const categoriesLevel = {
        categoryLv1: this.ticket.categoryLv1,
        categoryLv2: this.ticket.categoryLv2,
        categoryLv3: this.ticket.categoryLv3,
        categoryLv4: this.ticket.categoryLv4,
        categoryLv5: this.ticket.categoryLv5,
        categoryLv6: this.ticket.categoryLv6
      }
      const convertToArray = Object.keys(categoriesLevel).map((key) => categoriesLevel[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    },
    async showModal () {
      await this.getAllKnowledgeByCategory()
      this.isKnowledgeModal = true
    },
    showCurrentPageKnowledge (page) {
      this.setPaginationKnowledge(page)
      this.getAllKnowledgeByCategory()
    },
    setPaginationKnowledge (page, totalDocs) {
      this.knowledgePagination.page = page || this.knowledgePagination.page
      this.knowledgePagination.totalDocs = totalDocs || this.knowledgePagination.totalDocs
    },
    fetchSearchKnowledge () {
      this.setPaginationKnowledge(1)
      this.getAllKnowledgeByCategory()
    },
    chooseTicketType (e) {
      if (this.allRef && this.allRef.ticketType.length > 0) {
        const currentTicketType = this.allRef.ticketType.filter((item) => item.id === e)
        if (currentTicketType && currentTicketType.length > 0) {
          this.isRequiredCategory = currentTicketType[0].isRequiredCategory
        } else {
          this.isRequiredCategory = false
        }
      }
    },
    setInitialKnowledge () {
      this.ticket.ticketDetail = ''
      this.ticket.ticketResolve = ''
    }
  }
}
</script>

<style scoped>
::v-deep .ql-container.ql-snow>.ql-editor {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}
.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.remove-file {
  position: absolute;
  right: -17px;
  bottom: 11px;
}

.btn-outline-purple {
  color: #7367F0;
  border-color: #7367F0;
}

.btn-outline-purple:hover {
  background-color: #7267f010;
  color: #7367F0;
}

.btn-outline-purple:focus {
  background-color: #7267f041;
}
</style>
