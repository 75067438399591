var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._g(_vm._b({attrs:{"id":"email-modal","size":"xl","modal-class":"modal-normal","centered":"","ok-title":"Send"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"mx-auto pt-1"},[_c('h2',{staticClass:"font-weight-light"},[_vm._v(" Send Email ")])])]},proxy:true},{key:"modal-footer",fn:function(ref){
var hide = ref.hide;
return [_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success"}},[_vm._v(" Send ")]),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)]}}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},'b-modal',_vm.$attrs,false),Object.assign({}, _vm.$listeners)),[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"mb-2"},[_c('SearchTextInput',{attrs:{"label":"Search Favriote Email","placeholder":"Search Favriote Email "},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchFilter()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticStyle:{"max-width":"100%"}},[_c('b-table',{attrs:{"items":_vm.emailItems,"fields":_vm.fields,"striped":"","hover":"","responsive":""}})],1)]),_c('b-col',{attrs:{"cols":"12","md":"7"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(" To: "),_c('span',{staticClass:"label-required"},[_vm._v(" * ")])])]},proxy:true}])},[_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","taggable":"","close-on-select":false,"options":_vm.emailOptions,"input-id":"email-to"},on:{"input":function($event){return _vm.parseEmails('to', $event)}},nativeOn:{"keypress":function($event){return _vm.searchCustomerEmail($event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(" CC: ")])]},proxy:true}])},[_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","taggable":"","close-on-select":false,"options":_vm.emailOptions,"input-id":"email-to"},on:{"input":function($event){return _vm.parseEmails('cc', $event)}},nativeOn:{"keypress":function($event){return _vm.searchCustomerEmail($event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.cc),callback:function ($$v) {_vm.cc=$$v},expression:"cc"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('SelectField',{attrs:{"name":"Template","form-label":"Template","placeholder":"Template","label-option":"name","return-value":"id","options":_vm.templateOptions},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('TextFieldInput',{attrs:{"name":"Subject","label":"Subject","placeholder":"Subject","trim":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('wysiwyg',{model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }