<template>
  <div>
    <b-card no-body>
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive>
      </b-table>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)">
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketAppointmentAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="4">
                <DatePicker
                  v-model="appointment.date"
                  :rules="{ required: false }"
                  name="วันนัด"
                  label="วันนัด" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TimePicker
                  v-model="appointment.time"
                  name="เวลา"
                  label="เวลา"
                  :rules="{ required: false }" />
              </b-col>
              <b-col
                sm="12"
                md="4"></b-col>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="appointment.details"
                  :name="`รายละเอียดนัด`"
                  :label="`รายละเอียดนัด`" />
              </b-col>
            </b-row>
            <div class="d-block d-sm-flex align-items-center mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                class=""
                @click="addAppointment()">
                <div class="d-flex align-items-center">
                  <img
                    src="@/assets/images/icons/gala_add.svg" />
                  <span class="pl-1">เพิ่มนัด</span>
                </div>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import DatePicker from '@/components/Form/DatePicker.vue'
import TimePicker from '@/components/Form/TimePicker.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import AppointmentProvider from '@/resources/AppointmentProvider'

const AppointmentService = new AppointmentProvider()

export default {
  components: {
    DatePicker,
    TextAreaInput,
    TimePicker
  },
  props: {
    initialAppointment: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      fields: [
        { key: 'date', label: 'วันนัด' },
        { key: 'time', label: 'เวลา' },
        { key: 'details', label: 'รายละเอียดนัด' },
        { key: 'caseId', label: 'ผู้ใช้งาน' }
      ],
      items: [],
      filter: {
        searchDate: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      }
    }
  },
  computed: {
    appointment: {
      get () {
        return this.initialAppointment
      },
      set () {
        this.$emit('update:appointment-value')
      }
    }
  },
  async created () {
    try {
      // await this.getAppointmentByticketId()
      setInterval(async () => {
        await this.getAppointmentByticketId()
      }, 5000)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    resetForm () {
      this.appointment = { ...this.initialAppointment }
      this.$refs.TicketAppointmentAddForm.reset()
    },
    // async getAppointment () {
    //   const data = await AppointmentService.paginate(this.pagination.page, this.pagination.limit, this.filter)
    //   this.setPagination(data.page, data.totalDocs)
    //   this.items = [...data.data]
    // },
    async getAppointmentByticketId () {
      try {
        const data = await AppointmentService.findByTicketId(this.ticketId)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (error) {
        console.log('error', error)
      }
    },
    async addAppointment () {
      try {
        const payload = { ...this.appointment, caseId: this.ticketId }
        await AppointmentService.create(payload)
        // await this.getAppointment()
        this.resetForm()
        await this.getAppointmentByticketId()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Appointment has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    },
    showCurrentPage (page) {
      this.setPagination(page)
      // this.getAppointment()
      this.getAppointmentByticketId()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
