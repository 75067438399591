import HttpRequest from './HttpRequest'

class PaymentDetailProvider extends HttpRequest {
  findAll () {
    return this.get('/case-payment-details')
  }

  findOne (id) {
    return this.get(`/case-payment-details/${id}`)
  }

  create (payload) {
    return this.post('/case-payment-details', payload)
  }

  update (id, payload) {
    return this.patch(`/case-payment-details/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/case-payment-details/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/case-payment-details?page=${page}&limit=${limit}`, query)
  }
}

export default PaymentDetailProvider
