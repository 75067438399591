<template>
  <div>
    <b-row class="align-items-end">
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="4">
        <SearchTextInput
          v-model="searchName"
          label="ค้นหาชื่อ รพ/คลินิก"
          placeholder="ค้นหาชื่อ รพ/คลินิก"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="4">
        <FilterDropdownMultiple
          :value-model.sync="searchType"
          :options="allRef.typeOption"
          label="ประเภท"
          placeholder="ประเภท"
          label-option="label"
          return-value="value"
          @input="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="4"
        class="mb-1 mb-sm-0">
        <SearchTextInput
          v-model="searchProvince"
          label="จังหวัด"
          placeholder="จังหวัด"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="4"
        class="mb-1 mb-sm-0 mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="height: 40px;"
          variant="outline-primary"
          @click="fetchFilter()"
        >
          <span class="align-middle">Search</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import FilterDropdownMultiple from '@/components/Filter/FilterDropdownMultiple.vue'

export default {
  components: {
    SearchTextInput,
    FilterDropdownMultiple
  },
  props: {
    filter: {
      type: Object,
      default: () => (
        {
          searchName: '',
          searchType: [],
          searchProvince: ''
        }
      )
    },
    allRef: {
      type: Object,
      default: () => ({
        typeOption: []
      })
    }
  },
  computed: {
    searchName: {
      get () {
        return this.filter.searchName
      },
      set (val) {
        this.$emit('update:search-name', val)
      }
    },
    searchType: {
      get () {
        return this.filter.searchType
      },
      set (val) {
        this.$emit('update:search-type', val)
      }
    },
    searchProvince: {
      get () {
        return this.filter.searchProvince
      },
      set (val) {
        this.$emit('update:search-province', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
