<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketPaymentDetailAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="paymentDetail.totalComplaintAmount"
                  :rules="{ required: false }"
                  name="รวมจํานวนเงินที่ร้องเรียน"
                  label="รวมจํานวนเงินที่ร้องเรียน"
                  placeholder="รวมจํานวนเงินที่ร้องเรียน"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="paymentDetail.reimbursementAmountConditions"
                  :rules="{ required: false }"
                  name="จํานวนเงินที่ชดใช้ (ตามเงื่อนไข)"
                  label="จํานวนเงินที่ชดใช้ (ตามเงื่อนไข)"
                  placeholder="จํานวนเงินที่ชดใช้ (ตามเงื่อนไข)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="paymentDetail.reimbursementAmountOutsideConditions"
                  :rules="{ required: false }"
                  name="จํานวนเงินที่ชดใช้ (นอกเงื่อนไข)"
                  label="จํานวนเงินที่ชดใช้ (นอกเงื่อนไข)"
                  placeholder="จํานวนเงินที่ชดใช้ (นอกเงื่อนไข)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="paymentDetail.rejectAmount"
                  :rules="{ required: false }"
                  name="จํานวนเงินที่ปฎิเสธ"
                  label="จํานวนเงินที่ปฎิเสธ"
                  placeholder="จํานวนเงินที่ปฎิเสธ"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="paymentDetail.normalPremium"
                  :rules="{ required: false }"
                  name="นําเข้าเบี้ยปกติ"
                  label="นําเข้าเบี้ยปกติ"
                  placeholder="นําเข้าเบี้ยปกติ"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="paymentDetail.cancelRefund"
                  :rules="{ required: false }"
                  name="ยกเลิกคืนเบี้ย"
                  label="ยกเลิกคืนเบี้ย"
                  placeholder="ยกเลิกคืนเบี้ย"
                  trim />
              </b-col>
            </b-row>
            <div class="mt-5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addPaymentDetail()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import PaymentDetailProvider from '@/resources/PaymentDetailProvider'

const PaymentDetailService = new PaymentDetailProvider()

export default {
  components: {
    TextFieldInput
  },
  props: {
    initialPaymentDetail: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  computed: {
    paymentDetail: {
      get () {
        return this.initialPaymentDetail
      },
      set () {
        this.$emit('update:payment-detail-value')
      }
    }
  },
  methods: {
    resetForm () {
      this.$refs.TicketPaymentDetailAddForm.reset()
    },
    async addPaymentDetail () {
      try {
        const payload = { ...this.paymentDetail, caseId: this.ticketId }
        await PaymentDetailService.create(payload)
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Payment Detail has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
