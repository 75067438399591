<template>
  <b-modal
    id="email-modal"
    v-model="modalShow"
    v-bind="$attrs"
    size="xl"
    modal-class="modal-normal"
    centered
    ok-title="Submit"
    v-on="{...$listeners}">
    <template #modal-header>
      <div
        class="mx-auto pt-1">
        <h2 class="font-weight-light">
          เลือกชุดแบบสอบถามเพื่อส่งหาลูกค้า
        </h2>
      </div>
    </template>
    <b-row>
      <b-col
        cols="12"
        md="12">
        <div>
          <SelectField
            v-model="questionnaireSet"
            name="ชุดแบบสอบถาม"
            form-label="ชุดแบบสอบถาม"
            placeholder="ชุดแบบสอบถาม"
            label-option="name"
            return-value="id"
            :options="questionnaireSetOptions" />
        </div>
      </b-col>
    </b-row>
    <template #modal-footer="{ hide }">
      <div>
        <b-button
          class="mr-1"
          variant="success">
          Submit
        </b-button>
        <b-button
          variant="outline-danger"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import SelectField from '@/components/Form/SelectField.vue'

export default {
  components: {
    SelectField
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    initSubject: {
      type: String,
      default: ''
    },
    initMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      questionnaireSet: '',
      questionnaireSetOptions: [
        { id: 1, name: 'questionnaireSet 1' },
        { id: 2, name: 'questionnaireSet 2' }
      ]
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    fetchFilter () {
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: flex-start;
}

div ::v-deep .modal-header {
  background-color: white;
}
</style>
