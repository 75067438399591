<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketReimbursementAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="reimbursement.principle"
                  :rules="{ required: false }"
                  name="เงินต้น (บาท)"
                  label="เงินต้น (บาท)"
                  placeholder="เงินต้น (บาท)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="reimbursement.interestRate"
                  :rules="{ required: false }"
                  name="อัตราดอกเบี้ย (%)"
                  label="อัตราดอกเบี้ย (%)"
                  placeholder="อัตราดอกเบี้ย (%)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
              <b-col
                sm="12"
                md="4">
                <DatePicker
                  v-model="reimbursement.sinceDate"
                  :rules="{ required: false }"
                  name="ตั้งแต่วันที่"
                  label="ตั้งแต่วันที่" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <DatePicker
                  v-model="reimbursement.upToDate"
                  :rules="{ required: false }"
                  name="ถึงวันที่"
                  label="ถึงวันที่" />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="reimbursement.amountDayInterestCharged"
                  :rules="{ required: false }"
                  name="จํานวนวันที่คิดดอกเบี้ย (วัน)"
                  label="จํานวนวันที่คิดดอกเบี้ย (วัน)"
                  placeholder="จํานวนวันที่คิดดอกเบี้ย (วัน)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="reimbursement.totalInterest"
                  :rules="{ required: false }"
                  name="รวมดอกเบี้ย (บาท)"
                  label="รวมดอกเบี้ย (บาท)"
                  placeholder="รวมดอกเบี้ย (บาท)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="compoundInterest"
                  :rules="{ required: false }"
                  name="รวม เงินต้น+ดอกเบี้ย (บาท)"
                  label="รวม เงินต้น+ดอกเบี้ย (บาท)"
                  placeholder="รวม เงินต้น+ดอกเบี้ย (บาท)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="reimbursement.fees"
                  :rules="{ required: false }"
                  name="ค่าฤชาฯธรรมเนียม (บาท)"
                  label="ค่าฤชาฯธรรมเนียม (บาท)"
                  placeholder="ค่าฤชาฯธรรมเนียม (บาท)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="reimbursement.totalPayment"
                  :rules="{ required: false }"
                  name="รวมเงินจ่าย (บาท)"
                  label="รวมเงินจ่าย (บาท)"
                  placeholder="รวมเงินจ่าย (บาท)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <DatePicker
                  v-model="reimbursement.paymentDate"
                  :rules="{ required: false }"
                  name="วันที่จ่ายเงิน"
                  label="วันที่จ่ายเงิน" />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
            </b-row>
            <div class="mt-5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addReimbursement()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import DatePicker from '@/components/Form/DatePicker.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import ReimbursementProvider from '@/resources/ReimbursementProvider'

const ReimbursementService = new ReimbursementProvider()

export default {
  components: {
    TextFieldInput,
    DatePicker
  },
  props: {
    initialReimbursement: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  computed: {
    reimbursement: {
      get () {
        return this.initialReimbursement
      },
      set () {
        this.$emit('update:reimbursement-value')
      }
    },
    compoundInterest: {
      get () {
        return Number(this.reimbursement.principle) + Number(this.reimbursement.interestRate)
      },
      set (val) {
        this.$emit('update:compound-interest', val)
      }
    }
  },
  methods: {
    resetForm () {
      this.$refs.TicketReimbursementAddForm.reset()
    },
    async addReimbursement () {
      try {
        const payload = {
          principle: this.reimbursement.principle,
          interestRate: this.reimbursement.interestRate,
          sinceDate: this.reimbursement.sinceDate,
          upToDate: this.reimbursement.upToDate,
          amountDayInterestCharged: this.reimbursement.amountDayInterestCharged,
          totalInterest: this.reimbursement.totalInterest,
          compoundInterest: Number(this.reimbursement.principle) + Number(this.reimbursement.interestRate),
          fees: this.reimbursement.fees,
          totalPayment: this.reimbursement.totalPayment,
          paymentDate: this.reimbursement.paymentDate,
          caseId: this.ticketId
        }
        await ReimbursementService.create(payload)
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Reimbursement has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
