<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketCaseInformationAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="caseInformation.code"
                  :rules="{ required: false }"
                  name="รหัสคดี/Code"
                  label="รหัสคดี/Code"
                  placeholder=""
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <DatePicker
                  v-model="caseInformation.receiptDate"
                  :rules="{ required: false }"
                  name="วันที่รับเรื่อง"
                  label="วันที่รับเรื่อง" />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="caseInformation.arbitrationCourtId"
                  :rules="{ required: false }"
                  name="ศาล/อนุญาโตตุลาการ"
                  form-label="ศาล/อนุญาโตตุลาการ"
                  placeholder="ศาล/อนุญาโตตุลาการ"
                  label-option="name"
                  return-value="id"
                  :options="courtArbitrator"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="caseInformation.blackCase"
                  :rules="{ required: false }"
                  name="คดีดำ"
                  label="คดีดำ"
                  placeholder="คดีดำ"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="caseInformation.redCase"
                  :rules="{ required: false }"
                  name="Subject"
                  label="คดีแดง"
                  placeholder="คดีแดง"
                  trim />
              </b-col>
              <div
                v-for="(plaintiffValue, index) in caseInformation.plaintiff"
                :key="'plaintiff value' + index"
                class="w-100">
                <div class="d-flex">
                  <b-col
                    sm="12"
                    md="4">
                    <TextFieldInput
                      v-model="plaintiffValue.name"
                      :rules="{ required: false }"
                      name="Subject"
                      :label="'ชื่อโจทก์ที่ ' + (index + 1)"
                      placeholder="ชื่อโจทก์"
                      trim />
                  </b-col>
                  <b-col
                    sm="12"
                    md="4">
                    <SelectField
                      v-model="plaintiffValue.relationship"
                      :options="relationship"
                      :rules="{ required: false }"
                      name="ความเกี่ยวพันธ์"
                      form-label="ความเกี่ยวพันธ์"
                      placeholder="ความเกี่ยวพันธ์"
                      label-option="name"
                      return-value="name" />
                  </b-col>
                  <b-col
                    sm="12"
                    md="4">
                    <TextFieldInput
                      v-model="plaintiffValue.lawyer"
                      :rules="{ required: false }"
                      name="Subject"
                      label="ทนายโจทก์"
                      placeholder="ทนายโจทก์"
                      trim />
                  </b-col>
                </div>
                <div
                  v-if="caseInformation.plaintiff.length > 1">
                  <b-col
                    sm="12"
                    md="4">
                    <div class="d-block d-sm-flex align-items-center mb-2">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-danger"
                        class=""
                        @click="removePlaintiff(index)">
                        <div class="d-flex align-items-center">
                          <span>ลบโจทย์</span>
                        </div>
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </div>
              <b-col
                sm="12"
                md="4">
                <div class="d-block d-sm-flex align-items-center mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    class=""
                    @click="addPlaintiff()">
                    <div class="d-flex align-items-center">
                      <img
                        src="@/assets/images/icons/gala_add.svg" />
                      <span class="pl-1">เพิ่มโจทย์</span>
                    </div>
                  </b-button>
                </div>
              </b-col>
              <b-col
                sm="12"
                md="8"></b-col>
              <div
                v-for="(defendantValue, index) in caseInformation.defendant"
                :key="index"
                class="w-100">
                <div class="d-flex">
                  <b-col
                    sm="12"
                    md="4">
                    <TextFieldInput
                      v-model="defendantValue.name"
                      :rules="{ required: false }"
                      name="Subject"
                      :label="'ชื่อจําเลยที่ ' + (index + 1)"
                      placeholder="ชื่อจําเลย"
                      trim />
                  </b-col>
                  <b-col
                    sm="12"
                    md="4">
                    <SelectField
                      v-model="defendantValue.relationship"
                      :options="relationship"
                      :rules="{ required: false }"
                      name="ความเกี่ยวพันธ์"
                      form-label="ความเกี่ยวพันธ์"
                      placeholder="ความเกี่ยวพันธ์"
                      label-option="name"
                      return-value="name" />
                  </b-col>
                  <b-col
                    sm="12"
                    md="4">
                    <TextFieldInput
                      v-model="defendantValue.lawyer"
                      :rules="{ required: false }"
                      name="Subject"
                      label="ทนายจำเลย"
                      placeholder="ทนายจำเลย"
                      trim />
                  </b-col>
                </div>
                <div
                  v-if="caseInformation.defendant.length > 1">
                  <b-col
                    sm="12"
                    md="4">
                    <div class="d-block d-sm-flex align-items-center mb-2">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-danger"
                        class=""
                        @click="removeDefendant(index)">
                        <div class="d-flex align-items-center">
                          <span>ลบโจทย์</span>
                        </div>
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </div>
              <b-col
                sm="12"
                md="4">
                <div class="d-block d-sm-flex align-items-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    class=""
                    @click="addDefendant()">
                    <div class="d-flex align-items-center">
                      <img
                        src="@/assets/images/icons/gala_add.svg" />
                      <span class="pl-1">เพิ่มโจทย์</span>
                    </div>
                  </b-button>
                </div>
              </b-col>
              <b-col
                sm="12"
                md="8"></b-col>
            </b-row>
            <div class="mt-5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addCaseInformation()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import DatePicker from '@/components/Form/DatePicker.vue'
import SelectField from '@/components/Form/SelectField.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import CaseInformationProvider from '@/resources/CaseInformationProvider'
import CourtArbitrationProvider from '@/resources/CourtArbitrationProvider'
import ContactRelationProvider from '@/resources/ContactReletionProvider'

const CaseInformationService = new CaseInformationProvider()
const CourtArbitrationService = new CourtArbitrationProvider()
const ContactRelationService = new ContactRelationProvider()

export default {
  components: {
    TextFieldInput,
    DatePicker,
    SelectField
  },
  props: {
    initialCaseInformation: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      courtArbitrator: [],
      relationship: []
    }
  },
  computed: {
    caseInformation: {
      get () {
        return this.initialCaseInformation
      },
      set () {
        this.$emit('update:case-information-value')
      }
    }
  },
  async created () {
    await this.getCourtArbitrationGroup()
    await this.getContactRelationGroup()
  },
  methods: {
    resetForm () {
      this.$refs.TicketCaseInformationAddForm.reset()
    },
    addPlaintiff () {
      this.caseInformation.plaintiff.push({
        name: '',
        relationship: '',
        lawyer: ''
      })
    },
    removePlaintiff (index) {
      this.caseInformation.plaintiff.splice(index, 1)
    },
    addDefendant () {
      this.caseInformation.defendant.push({
        name: '',
        relationship: '',
        lawyer: ''
      })
    },
    removeDefendant (index) {
      this.caseInformation.defendant.splice(index, 1)
    },
    async getCourtArbitrationGroup () {
      try {
        const { data } = await CourtArbitrationService.findAll()
        this.courtArbitrator = data
      } catch (error) {
        console.log('error', error)
      }
    },
    async getContactRelationGroup () {
      try {
        const { data } = await ContactRelationService.findAll()
        this.relationship = data
      } catch (error) {
        console.log('error', error)
      }
    },
    async addCaseInformation () {
      try {
        const payload = { ...this.caseInformation, caseId: this.ticketId }
        await CaseInformationService.create(payload)
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Case Information has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
