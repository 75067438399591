import HttpRequest from './HttpRequest'

class ReimbursementProvider extends HttpRequest {
  findAll () {
    return this.get('/reimbursement')
  }

  findOne (id) {
    return this.get(`/reimbursement/${id}`)
  }

  create (payload) {
    return this.post('/reimbursement', payload)
  }

  update (id, payload) {
    return this.patch(`/reimbursement/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/reimbursement/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/reimbursement?page=${page}&limit=${limit}`, query)
  }
}

export default ReimbursementProvider
