<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketIndictmentAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="4">
                <DatePicker
                  v-model="indictment.filedDate"
                  :rules="{ required: false }"
                  name="วันที่ยื่นห้อง"
                  label="วันที่ยื่นห้อง" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="indictment.money"
                  :rules="{ required: false }"
                  name="ทุนทรัพย์ (บาท)"
                  label="ทุนทรัพย์ (บาท)"
                  placeholder="ทุนทรัพย์ (บาท)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4"></b-col>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="indictment.finalPetition"
                  :name="`คําขอท้ายฟ้อง`"
                  :label="`คําขอท้ายฟ้อง`" />
              </b-col>
            </b-row>
            <div class="mt-5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addIndictment()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import DatePicker from '@/components/Form/DatePicker.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import IndictmentProvider from '@/resources/IndictmentProvider'

const IndictmentService = new IndictmentProvider()

export default {
  components: {
    DatePicker,
    TextFieldInput,
    TextAreaInput
  },
  props: {
    initialIndictment: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: Number,
      default: null
    }
  },
  computed: {
    indictment: {
      get () {
        return this.initialIndictment
      },
      set () {
        this.$emit('update:indictment-value')
      }
    }
  },
  methods: {
    resetForm () {
      this.$refs.TicketIndictmentAddForm.reset()
    },
    async addIndictment () {
      try {
        const payload = { ...this.indictment, caseId: this.ticketId }
        await IndictmentService.create(payload)
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 indictment has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
