import HttpRequest from './HttpRequest'

class AppointmentProvider extends HttpRequest {
  findAll () {
    return this.get('/appointment')
  }

  findOne (id) {
    return this.get(`/appointment/${id}`)
  }

  findByTicketId (id) {
    return this.get(`/appointment/all/case-id/${id}`)
  }

  create (payload) {
    return this.post('/appointment', payload)
  }

  update (id, payload) {
    return this.patch(`/appointment/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/appointment/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/appointment?page=${page}&limit=${limit}`, query)
  }
}

export default AppointmentProvider
