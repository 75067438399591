<template>
  <div class="shadow mt-2">
    <b-card
      no-body>
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive>
        <template #head(caseNo)="data">
          <span>{{ data.label }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fields: [
        { key: 'caseNo', label: 'Case No.' },
        { key: 'notificationDate', label: 'วันที่แจ้งเรื่อง' },
        { key: 'CustomerName', label: 'ชื่อลูกค้า' },
        { key: 'policyNumber', label: 'เลขที่กรมธรรม์' },
        { key: 'caseType', label: 'Case Type' },
        { key: 'title', label: 'หัวเรื่อง/Title' },
        { key: 'caseStatus', label: 'Case Status' },
        { key: 'systemUser', label: 'ผู้ใช้งานระบบ' },
        { key: 'view', label: 'View' }
      ],
      items: [
        {
          caseNo: '001',
          notificationDate: 'test001',
          CustomerName: 'test001',
          policyNumber: 'test001',
          caseType: 'test001',
          title: 'test001',
          caseStatus: 'test001',
          systemUser: 'test001',
          view: 'test001'
        },
        {
          caseNo: '001',
          notificationDate: 'test001',
          CustomerName: 'test001',
          policyNumber: 'test001',
          caseType: 'test001',
          title: 'test001',
          caseStatus: 'test001',
          systemUser: 'test001',
          view: 'test001'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
