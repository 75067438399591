<template>
  <b-modal
    id="email-modal"
    v-model="modalShow"
    v-bind="$attrs"
    size="xl"
    modal-class="modal-normal"
    centered
    ok-title="Send"
    v-on="{...$listeners}">
    <template #modal-header>
      <div
        class="mx-auto pt-1">
        <h2 class="font-weight-light">
          Send Email
        </h2>
      </div>
    </template>
    <b-row>
      <b-col
        cols="12"
        md="5"
      >
        <div class="mb-2">
          <SearchTextInput
            v-model="search"
            label="Search Favriote Email"
            placeholder="Search Favriote Email "
            @keyup.enter.native="fetchFilter()" />
        </div>
        <div style="max-width: 100%">
          <b-table
            :items="emailItems"
            :fields="fields"
            striped
            hover
            responsive>
          </b-table>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="7">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                <label>
                  To:
                  <span
                    class="label-required">
                    *
                  </span>
                </label>
              </template>
              <v-select
                v-model="to"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                taggable
                class="flex-grow-1 email-to-selector"
                :close-on-select="false"
                :options="emailOptions"
                input-id="email-to"
                @input="parseEmails('to', $event)"
                @keypress.native="searchCustomerEmail($event)"
              >
                <template #option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                <label>
                  CC:
                </label>
              </template>
              <v-select
                v-model="cc"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                taggable
                class="flex-grow-1 email-to-selector"
                :close-on-select="false"
                :options="emailOptions"
                input-id="email-to"
                @input="parseEmails('cc', $event)"
                @keypress.native="searchCustomerEmail($event)"
              >
                <template #option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <SelectField
              v-model="template"
              name="Template"
              form-label="Template"
              placeholder="Template"
              label-option="name"
              return-value="id"
              :options="templateOptions" />
          </b-col>
          <b-col cols="12">
            <TextFieldInput
              v-model="subject"
              name="Subject"
              label="Subject"
              placeholder="Subject"
              trim />
          </b-col>
          <b-col cols="12">
            <wysiwyg v-model="detail" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template #modal-footer="{ hide }">
      <div>
        <b-button
          class="mr-1"
          variant="success">
          Send
        </b-button>
        <b-button
          variant="outline-danger"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'

export default {
  components: {
    SearchTextInput,
    SelectField,
    TextFieldInput
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    initSubject: {
      type: String,
      default: ''
    },
    initMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      to: [],
      cc: [],
      template: [],
      subject: '',
      detail: '',
      search: '',
      fields: [
        { key: 'email', label: 'Email' },
        { key: 'to', label: '' },
        { key: 'cc', label: '' }
      ],
      emailItems: [],
      emailToOptions: [],
      templateOptions: [
        { id: 1, name: 'template 1' },
        { id: 2, name: 'template 2' }
      ]

    }
  },
  computed: {
    emailOptions () {
      return [...new Set(this.emailToOptions)]
    },
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    fetchFilter () {
    },
    searchCustomerEmail () {
    },
    parseEmails (type, data) {
      const { length } = data
      const email = data[length - 1]
      const emailArray = email.split(/[;,]+/)
      if (type === 'to') {
        const { to } = this

        if (Array.isArray(data) && Array.isArray(to) && typeof email === 'string' && email) {
          if (emailArray.length > 0) {
            to.pop()
            to.push(...emailArray)
          }
        }

        const newEmail = []
        for (const item of this.to) {
          if (this.isValidEmail(item)) {
            newEmail.push(item)
          }
        }

        this.to = [...new Set(newEmail)]
      }
      if (type === 'cc') {
        const { cc } = this
        if (Array.isArray(data) && Array.isArray(cc) && typeof email === 'string' && email) {
          if (emailArray.length > 0) {
            cc.pop()
            cc.push(...emailArray)
          }
        }

        const newEmail = []
        for (const item of this.cc) {
          if (this.isValidEmail(item)) {
            newEmail.push(item)
          }
        }

        this.cc = [...new Set(newEmail)]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div ::v-deep .modal-header {
  background-color: white;
}

</style>
