<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketSatisfactionAssessmentAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <p>1. รบกวนคุณลูกค้า ประเมินความพึงพอใจ 1-5 โดย 5 คือ พึงพอใจมากที่สุด เพื่อพัฒนาบริการให้ดีขึ้นต่อไปครับ</p>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <b-form-input
                  type="text"
                  class="ml-1"></b-form-input>
              </b-col>
            </b-row>
            <p class="pt-1">
              2. ท่านจะแนะนำหรือบอกต่อ OCEANLIFE ไทยสมุทรให้กับคนอื่นไหมครับ ให้คะแนน 1-10 โดย 10 คือแนะนำอย่างยิ่ง
            </p>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <b-form-input
                  type="text"
                  class="ml-1"></b-form-input>
              </b-col>
            </b-row>
            <p class="pt-1">
              3. Comment อื่นๆ เพิ่มเติม
            </p>
            <b-row>
              <b-col
                sm="12"
                md="6">
                <b-form-textarea
                  rows="3"
                  class="ml-1"
                ></b-form-textarea>
              </b-col>
              <b-col
                sm="12"
                md="6">
              </b-col>
              <b-col
                sm="12"
                md="6">
                <div class="ml-1 mt-5">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class=""
                    variant="success"
                    @click="addPaymentDetail()">
                    <span class="align-middle"> Save </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>

export default {
  methods: {
    resetForm () {
      this.$refs.TicketSatisfactionAssessmentAddForm.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.text--bold {
  font-weight: bold;
}
</style>
