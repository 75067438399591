<template>
  <b-modal
    id="modal-danger"
    ref="confirm"
    v-model="isVisible"
    v-bind="$attrs"
    :ok-variant="variant"
    ok-title="Accept"
    modal-class="modal-normal"
    centered
    v-on="{ ...$listeners }">
    <div class="d-block text-center mb-1">
      <p class="font-weight-bold h2">
        Refer Ticket
      </p>
    </div>
    <div
      class="mx-auto mb-2"
    >
      <TextFieldInput
        v-model="ticketCodeReferValue"
        :rules="{ required: false }"
        name="Case Code"
        label="Case Code"
        placeholder="Input Case Code"
        trim />
    </div>
    <template #modal-footer="{ hide }">
      <div class="mx-auto">
        <b-button
          :variant="variant"
          style="margin-right: 12px"
          @click="_confirm()">
          Save
        </b-button>
        <!-- <b-button
          v-if="ticketCodeReferValue"
          variant="danger"
          style="margin-right: 12px"
          @click="remove()">
          Delete
        </b-button> -->
        <b-button
          variant="outline-primary"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'

export default {
  components: {
    TextFieldInput
  },
  props: {
    ticketCodeRefer: {
      type: String,
      default: ''
    }
    // variant: {
    //   type: String,
    //   default: 'primary'
    // }
    // title: {
    //   type: String,
    //   default: 'Title'
    // },
    // description: {
    //   type: String,
    //   default: 'Description'
    // }
  },
  data () {
    return {
      title: 'Are you sure?',
      description: '',
      variant: 'danger',
      isVisible: false,
      resolvePromise: null,
      rejectPromise: null
    }
  },
  computed: {
    ticketCodeReferValue: {
      get () {
        return this.ticketCodeRefer
      },

      set (val) {
        this.$emit('update-ticket-code-refer', val)
      }
    }
  },
  methods: {
    show ({ title, description, variant }) {
      this.title = title || 'Are you sure?'
      this.description = description
      this.variant = variant || 'danger'
      this.isVisible = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm () {
      this.isVisible = false
      this.resolvePromise(true)
    },

    _cancel () {
      this.isVisible = false
      this.resolvePromise(false)
    },

    remove () {
      this.isVisible = false
      this.ticketCodeReferValue = null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
