import HttpRequest from './HttpRequest'

class CaseInformationProvider extends HttpRequest {
  findAll () {
    return this.get('/case-information')
  }

  findOne (id) {
    return this.get(`/case-information/${id}`)
  }

  create (payload) {
    return this.post('/case-information', payload)
  }

  update (id, payload) {
    return this.patch(`/case-information/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/case-information/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/case-information?page=${page}&limit=${limit}`, query)
  }
}

export default CaseInformationProvider
