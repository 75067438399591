import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CustomerGroupProvider extends HttpRequest {
  findAll () {
    return this.get('/case-cis')
  }

  findAllTicket () {
    return this.get('/case-cis/dashboard/all')
  }

  findAllStatus (userId) {
    return this.get(`/case-cis/status/all?userId=${userId}`)
  }

  findOne (id) {
    return this.get(`/case-cis/${id}`)
  }

  findByCode (code) {
    return this.get(`/case-cis/${code}/code`)
  }

  findByCustomerProfile (id, page = 1, limit = 10, query) {
    return this.get(`/case-cis/customer-profile/${id}?page=${page}&limit=${limit}${generateQuery(query)}`)
  }

  createGroup (payload) {
    return this.post('/case-cis', payload)
  }

  createInitial (userId) {
    return this.post(`/case-cis/initial?userId=${userId}`)
  }

  createTicketByCustomer (customerId, userId) {
    return this.post(`/case-cis/${customerId}/customer?userId=${userId}`)
  }

  duplicateTicket (payload) {
    return this.post('/case-cis/duplicate', payload)
  }

  reOpenTicket (id, payload) {
    return this.post(`/case-cis/${id}/re-open`, payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/case-cis/${id}`, payload)
  }

  voidTicket (id) {
    return this.post(`/case-cis/${id}/void`)
  }

  deleteGroup (id) {
    return this.delete(`/case-cis/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/case-cis?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default CustomerGroupProvider
