<template>
  <div>
    <HospitalInformationDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :type-option="allRef.typeOption"
      :initial-hospital-information="initialHospitalInformation"
      :selected-item.sync="selectedItem"
      @edit-hospital-information="editHospitalInformation($event)" />
    <HospitalInformationFilter
      class="p-1"
      :filter="filter"
      :all-ref="allRef"
      @update:search-name="filter.searchName=$event"
      @update:search-type="filter.searchType=$event"
      @update:search-province="filter.searchProvince=$event"
      @fetch-filter="fetchFilter()" />
    <div class="px-1 pt-2">
      <div class="shadow">
        <b-card
          no-body>
          <div class="zoom-80">
            <b-table
              :items="items"
              :fields="fields"
              striped
              hover
              responsive
              @sort-changed="sortFilter($event)"
              @row-clicked="rowClick($event)">
            </b-table>
            <b-row>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="pagination.page"
                  :total-rows="pagination.totalDocs"
                  :per-page="pagination.limit"
                  first-number
                  last-number
                  class="mb-1 mt-2 mr-1"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="showCurrentPage($event)">
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import HospitalContactProvider from '@/resources/HospitalContactProvider'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import HospitalInformationFilter from './HospitalInformationFilter.vue'
import HospitalInformationDetail from './HospitalInformationDetail.vue'

const HospitalContactService = new HospitalContactProvider()

export default {
  components: {
    HospitalInformationFilter,
    HospitalInformationDetail
  },
  data () {
    return {
      isSidebarDetailActive: false,
      filter: {
        searchName: '',
        searchType: [],
        searchProvince: ''
      },
      allRef: {
        typeOption: [
          { label: 'คลินิก', value: 'คลินิก' },
          { label: 'สถานพยาบาล', value: 'สถานพยาบาล' }
        ]
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'address', label: 'Address' },
        { key: 'subDistrict', label: 'SubDistrict' },
        { key: 'district', label: 'District' },
        { key: 'province', label: 'Province' },
        { key: 'zipcode', label: 'Zipcode' },
        { key: 'phoneNo', label: 'Phone No.' },
        { key: 'faxNo', label: 'Fax No.' },
        { key: 'ind', label: 'Ind' },
        { key: 'group', label: 'Group' },
        { key: 'webSite', label: 'Web Site' },
        { key: 'locationGps', label: 'Location GPS' },
        { key: 'contactPerson', label: 'Contact Person' },
        { key: 'department', label: 'Department' },
        { key: 'email', label: 'Email' },
        { key: 'contactPhoneNo', label: 'Contact Phone No.' },
        { key: 'activeDate', label: 'Active Date' },
        { key: 'expireDate', label: 'Expire Date' },
        { key: 'promotion', label: 'Promotion' }
      ],
      items: [],
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      initialHospitalInformation: {},
      selectedItem: {}
    }
  },
  created () {
    this.getHospitalInformation()
  },
  methods: {
    fetchFilter () {
      this.setPagination(1)
      this.getHospitalInformation()
    },
    async getHospitalInformation () {
      const data = await HospitalContactService.paginate(this.pagination.page, this.pagination.limit, this.filter)
      this.setPagination(data.page, data.totalDocs)
      this.items = [...data.data]
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getHospitalInformation()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    },
    async editHospitalInformation (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await HospitalContactService.update(val.id, payload)
        await this.getHospitalInformation()
        this.setInitialHospitalInformationAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Hospital Information has been updated'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Hospital Contact has been Failed'
          }
        })
      }
    },
    rowClick (val) {
      this.setInitialHospitalInformationAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialHospitalInformationAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialHospitalInformation = { ...val }
    },
    sortFilter (ctx) {
      console.log(ctx)
    }
  }
}
</script>

<style lang="scss" scoped>
.font--weight {
  font-weight: bold;
}
.zoom-80 {
  zoom: 80%;
}
</style>
