import HttpRequest from './HttpRequest'

class IndictmentProvider extends HttpRequest {
  findAll () {
    return this.get('/indictment')
  }

  findOne (id) {
    return this.get(`/indictment/${id}`)
  }

  create (payload) {
    return this.post('/indictment', payload)
  }

  update (id, payload) {
    return this.patch(`/indictment/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/indictment/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/indictment?page=${page}&limit=${limit}`, query)
  }
}

export default IndictmentProvider
