<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Hospital Information Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="HospitalInformationDetailForm">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="hospitalInformation.name"
            label="Name"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.type"
            label="Type"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.address"
            label="Address"
            disabled
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalInformation.subDistrict"
            label="SubDistrict"
            disabled
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalInformation.district"
            label="District"
            disabled
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalInformation.province"
            label="Province"
            disabled
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalInformation.zipcode"
            label="ZipCode"
            disabled
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalInformation.phoneNo"
            label="Phone No."
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.faxNo"
            label="Fax No."
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.ind"
            label="Ind"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.group"
            label="Group"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.webSite"
            label="Web Site"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.locationGps"
            label="Location GPS"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.contactPerson"
            label="Contact Person"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.department"
            label="Department"
            disabled
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalInformation.email"
            label="Email"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.contactPhoneNo"
            label="Contact Phone No."
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.activeDate"
            label="Active Date"
            :rules="{ required: false }"
            disabled
            trim />
          <TextFieldInput
            v-model="hospitalInformation.expireDate"
            label="Expire Date"
            :rules="{ required: false }"
            disabled
            trim />
          <TextAreaInput
            v-model="hospitalInformation.promotion"
            disabled
            :label="`Promotion`" />
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'

export default {
  components: {
    TextFieldInput,
    TextAreaInput
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialHospitalInformation: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    typeOption: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    hospitalInformation: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.hospitalInformation) === JSON.stringify(this.initialHospitalInformation)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.hospitalInformation.id,
        name: this.hospitalInformation.name,
        type: this.hospitalInformation.type,
        address: this.hospitalInformation.address,
        subDistrict: this.hospitalInformation.subDistrict,
        district: this.hospitalInformation.district,
        province: this.hospitalInformation.province,
        zipcode: this.hospitalInformation.zipcode,
        phoneNo: this.hospitalInformation.phoneNo,
        faxNo: this.hospitalInformation.faxNo,
        ind: this.hospitalInformation.ind,
        group: this.hospitalInformation.group,
        webSite: this.hospitalInformation.webSite,
        locationGps: this.hospitalInformation.locationGps,
        contactPerson: this.hospitalInformation.contactPerson,
        department: this.hospitalInformation.department,
        email: this.hospitalInformation.email,
        contactPhoneNo: this.hospitalInformation.contactPhoneNo,
        activeDate: this.hospitalInformation.activeDate,
        expireDate: this.hospitalInformation.expireDate,
        promotion: this.hospitalInformation.promotion,
        status: this.hospitalInformation.status
      }
      this.$emit('edit-hospital-information', payload)
    },
    resetForm () {
      this.hospitalInformation = { ...this.initialHospitalInformation }
      this.$refs.HospitalInformationDetailForm.reset()
    }
  }
}
</script>

<style>

</style>
